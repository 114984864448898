import React, { useState, useEffect } from "react"
import { Tags } from "@styled-icons/fa-solid/Tags"
import { flashSaleWrapper, flashSaleIcon, flashSaleText, discountValue, discountText } from "./flashSale.module.scss"
import { blackFridayTimer } from "consts/helper"

const FlashSale = ({ value = 70, endDate }) => {
  const [countDown, setCountDown] = useState({ hidden: false })

  useEffect(() => {
    if (endDate) {
      const interval = setInterval(() => {
        const countDown = blackFridayTimer(endDate)
        if (countDown?.distance > 0) {
          setCountDown(countDown)
        } else {
          setCountDown({
            hidden: true,
          })
          return () => clearInterval(interval)
        }
      }, 1000)
      return () => clearInterval(interval)
    }
  }, [])

  if (!countDown.distance || countDown.hidden) return null

  const { days, hours, minutes, seconds } = countDown

  return (
    <div className={flashSaleWrapper}>
      <div className={flashSaleIcon}>
        <Tags size={20} />
      </div>
      <div className={flashSaleText}>
        <span className={discountValue}>{value}% off : </span>{" "}
        <span className={discountText}>
          {days > 1 ? `${days} days` : days === 1 ? `${days} day` : ""}{" "}
          {hours > 1 ? `${hours} hrs` : hours === 1 ? `${hours} hr` : ""}{" "}
          {minutes > 1 ? `${minutes} mins` : minutes === 1 ? `${minutes} min` : ""}
          {` ${seconds} sec`} left
        </span>
      </div>
    </div>
  )
}

export default FlashSale
