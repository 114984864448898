import React from "react"

const QuantityPicker = ({ qty = 1, qtyIncrease, qtyDecrease, kmkSku }) => {
  return (
    <div className="product-qty-wrapper">
      <div className="input-wrapper">
        <div className="input-group input-qty">
          <span className="input-group-btn">
            <button
              type="button"
              className="quantity-left-minus btn  btn-number"
              data-type="minus"
              data-field=""
              // disabled={kmkSku === "Gift Card"}
              onClick={() => qtyDecrease()}
            >
              <span>&#8722;</span>
            </button>
          </span>
          <div className="product-qty-number">{qty}</div>
          <span className="input-group-btn">
            <button
              type="button"
              className="quantity-right-plus btn btn-number"
              data-type="plus"
              data-field=""
              // disabled={kmkSku === "Gift Card"}
              onClick={() => qtyIncrease()}
            >
              <span>&#43;</span>
            </button>
          </span>
        </div>
      </div>
    </div>
  )
}

export default QuantityPicker
