import React, { useEffect, useState } from "react"

const Section = ({ className, children, bgColor, bgImg, btmPadding, bgMobileImage }) => {
  const [bgImage, setBgImage] = useState(bgImg)

  useEffect(() => {
    window.outerWidth > 767 ? setBgImage(bgImg) : setBgImage(bgMobileImage)
    window.addEventListener("resize", () => {
      window.outerWidth > 767 ? setBgImage(bgImg) : setBgImage(bgMobileImage)
    })
  })

  return (
    <section
      className={`section ${className}`}
      style={{
        backgroundColor: bgColor,
        backgroundImage: bgImage && `url(${bgImage})`,
        padding: btmPadding && `50px 0 0`,
      }}
    >
      {children}
    </section>
  )
}

export default Section
