import React, { useContext, useEffect, useState } from "react"
import {
  contentContainer,
  popupActive,
  popupWrapper,
  popupContainer,
  rebuyHeading,
  popupContent,
  backdrop,
  closeIcon,
} from "./rebuyProducts.module.scss"
import useProductsFlavorsShopiyVariants from "hooks/useProductsFlavorsShopiyVariants"
import ReBuyRecommended from "components/molecules/ReBuyRecommented/ReBuyRecommented"
import Axios from "axios"
import { StoreContext } from "../../../context/store"
import useBlackFriday from "hooks/useBlackFriday"
import { filterRecommendedProducts } from "consts/helper"

const RebuyProducts = ({ onClose }) => {
  const [recommendedProducts, setRecommendedProducts] = useState([])
  const { allProducts, shopifyVariants } = useProductsFlavorsShopiyVariants()
  const { enableUpSellPopup } = useBlackFriday()
  const { cartProds } = useContext(StoreContext)

  //get products ids from context
  const handleProdIds = () => {
    if (cartProds?.length) {
      let ids = ""
      cartProds.map((prod, idx) => {
        ids += `${prod}${idx + 1 !== cartProds.length ? "," : ""}`
      })
      return ids
    } else {
      return ""
    }
  }

  useEffect(() => {
    if (enableUpSellPopup) {
      Axios({
        url: "https://rebuyengine.com/api/v1/products/recommended",
        params: {
          key: process.env.GATSBY_REBUY_API_KEY,
          limit: 10,
          shopify_product_ids: handleProdIds(),
        },
      })
        .then(response => {
          const localData = JSON.parse(localStorage.getItem("cartData"))
          const productNameValue = localData[localData.length - 1].name
            ? localData[localData.length - 1].name.toLowerCase()
            : localData[localData.length - 2].name.toLowerCase()
          const filteredProducts = filterRecommendedProducts(response.data.data, productNameValue)
          const reBuyItems = filteredProducts.map(reBuyItemProduct => {
            // TODO: comment out hardcoded ID after promotion and uncomment dynamic assignment
            // const rebuyId = reBuyItemProduct?.id
            const rebuyId = "6739832438871"
            const rebuyItem = allProducts.nodes.filter(prod => prod?.shopifyProductId == rebuyId)[0]
            rebuyItem.categories = [reBuyItemProduct?.product_type]
            rebuyItem.flavorItem = rebuyItem.flavors[0]
            return rebuyItem
          })
          setRecommendedProducts(reBuyItems)
        })
        .catch(error => {
          console.log(error)
        })
    }
  }, [])

  return (
    <>
      {recommendedProducts.length > 0 && enableUpSellPopup && (
        <div className={popupActive}>
          <div className={popupWrapper}>
            <div className={popupContainer}>
              <div className={backdrop} onClick={onClose} />
              <div className={popupContent}>
                <div className={closeIcon} onClick={onClose}>
                  <i className="icon-close" aria-hidden="true" />
                </div>
                <div className={contentContainer}>
                  {/* <h1 className={rebuyHeading}>YOUR ORDER GOES GREAT WITH</h1> */}
                  <h1 className={rebuyHeading}>GET 50% OFF COOKIES!</h1>
                  <ReBuyRecommended
                    allProducts={allProducts}
                    shopifyVariants={shopifyVariants.nodes}
                    recommendedProducts={recommendedProducts}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default RebuyProducts
