import React from "react"

const DiscountCompare = ({ isSubscribe, price, comparePrice, quantity, showPrice = true }) => {
  // Calculate the percentage from the compare price
  const calculatePercentageDifference = (compare, price) => {
    const discount = ((comparePrice - price) / comparePrice) * 100
    return discount.toFixed(2)
  }

  const discountPercentage = calculatePercentageDifference(comparePrice, price)

  const savingAmount = (comparePrice - price).toFixed(2)

  const classes = !isSubscribe ? "save active" : "save"

  return (
    <>
      <span className={classes}>
        You save: ${(savingAmount * quantity).toFixed(2)}
        {` (${discountPercentage} %)`}
      </span>
      {showPrice && (
        <span className="price">
          <span className={`${classes} main-price`}>${(comparePrice * quantity).toFixed(2)}</span>
        </span>
      )}
    </>
  )
}

export default DiscountCompare
