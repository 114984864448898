import Slider from "react-slick"
import React, { useContext, useEffect, useState } from "react"

import {
  sliderItemContainer,
  productInfo,
  claimBtn,
  titlePriceContainer,
  pdSale,
  pdSlash,
} from "./RebuyRecommended.module.scss"
import { currencyFormat, round, SelectOptionFlavors } from "consts/helper"
import CustomSelect from "components/atoms/CustomSelect"
import { StoreContext } from "../../../context/store"
import useWindowSize from "hooks/useWindowSize"
import Image from "components/atoms/Image"

const ReBuyRecommended = ({ shopifyVariants, recommendedProducts }) => {
  const { isMobile } = useWindowSize()
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: isMobile || recommendedProducts.length === 1 ? 1 : 3,
    slidesToScroll: 1,
  }
  const qty = 1
  const { addToCart, setReBuyPopupOpen } = useContext(StoreContext)
  const [isFlavorChanged, setFlavorChanged] = useState(false)

  useEffect(() => {}, [isFlavorChanged])

  const handlePrice = flavor => {
    const discountedPrice = round(((flavor?.price * (100 - (qty - 1) * 3)) / 100) * qty, 2)
    if (!flavor?.allowDiscount) {
      return round(flavor?.price * qty, 2)
    }
    return discountedPrice
  }

  const handleFlavor = (event, recommendedItem) => {
    recommendedItem.flavors.forEach(flavor => {
      if (flavor.sku === event.value && flavor.flavor === event.label) {
        recommendedItem.flavorItem = flavor
        setFlavorChanged(prevState => !prevState)
      }
    })
  }

  const handleAddToCart = recommendedItem => {
    let correctSku = ""
    const selFlavor = recommendedItem.flavorItem

    if (selFlavor.allowDiscount) {
      correctSku = `${selFlavor.sku}${qty > 1 ? `-${qty}` : ""}`
    } else {
      correctSku = selFlavor.sku
    }

    const selVariant = shopifyVariants.filter(variant => variant.sku === correctSku)[0]

    const inLineItem = {
      categories: recommendedItem.categories,
      kmkIsSubscribe: false,
      kmkPrice: selVariant.price,
      kmkImage: selFlavor.images[0],
      kmkTitle: selVariant.title,
      kmkSku: selFlavor.sku,
      kmkQuantity: qty,
      kmkDiscount: recommendedItem._rawSubscriptionSettings.initialDiscount,
      kmkAllowDiscount: selFlavor?.allowDiscount,
      charge_interval_frequency: null,
      fulfillment_service: "manual",
      order_interval_frequency: null,
      order_interval_unit: null,
      product_id: recommendedItem.shopifyProductId,
      quantity: qty,
      variant_id: selVariant?.shopifyId?.replace("gid://shopify/ProductVariant/", ""),
      storefrontId: selVariant.storefrontId,
      name: selFlavor.name,
      id: selFlavor._id,
    }
    addToCart(inLineItem)
    setReBuyPopupOpen(false)
  }

  return (
    <div className="reBuySliderContainer">
      <Slider {...settings}>
        {recommendedProducts.length > 0 &&
          recommendedProducts.map(recommendedItem => {
            return (
              <div className={sliderItemContainer} key={recommendedItem.flavorItem.price}>
                <div className="row-col">
                  <div className="col-left">
                    <div className="img-box">
                      {recommendedItem?.flavorItem?.images?.length > 0 && (
                        <Image image={recommendedItem?.flavorItem?.images[0]?.asset} />
                      )}
                    </div>
                  </div>
                  <div className={productInfo}>
                    <div className={titlePriceContainer}>
                      <div style={{ display: "flex", justifyContent: "center" }}>
                        <p className={pdSlash}>$71.96</p>
                        <p className={`${pdSale} pd-price`}>${currencyFormat(recommendedItem.flavorItem.price)}</p>
                      </div>
                      <p className="pd-title">{recommendedItem.name}</p>
                    </div>
                    <div className="add-to-order-wrapper">
                      <div className="flavor-select">
                        <CustomSelect
                          options={SelectOptionFlavors(recommendedItem?.flavors)}
                          placeholder={recommendedItem?.flavorItem?.flavor}
                          onChange={event => handleFlavor(event, recommendedItem)}
                        />
                      </div>
                      <button
                        className={`${claimBtn} btn btn-primary btn-add-to-order`}
                        onClick={() => handleAddToCart(recommendedItem)}
                      >
                        Claim this order
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
      </Slider>
    </div>
  )
}

export default ReBuyRecommended
