import React, { useContext } from "react"

import Container from "../../atoms/Container"
import Section from "../../atoms/Section"

import PhoneInput from "react-phone-input-2"

import "react-phone-input-2/lib/material.css"
import { StoreContext } from "context/store"

const SignUpSection = () => {
  const [phone, setPhone] = React.useState("")
  const { handlePostscriptSubscriberRegister } = useContext(StoreContext)

  const handleSubmitForm = e => {
    e.preventDefault()
    handlePostscriptSubscriberRegister(phone)
  }
  return (
    <Section className="signup-sec inner-padding">
      <Container>
        <h2 className="title text-center">
          Want More Kiss My Keto?
          <p className="tag-line">Sign up for SMS and get 10% OFF sent right to your phone!</p>
        </h2>

        <div className="row">
          <div className="col-sm-12">
            <div className="signup-form-wrapper">
              <form className="signup-form" onSubmit={handleSubmitForm}>
                {/* <input
                  id="phone"
                  name="phone"
                  type="tel"
                  className="country-control"
                  value={phone}
                  onChange={e => setPhone(e.target.value)}
                /> */}
                <PhoneInput
                  containerClass="country-control"
                  country="us"
                  inputProps={{
                    name: "phone",
                    required: true,
                  }}
                  onChange={setPhone}
                />
                <button type="submit" className="btn-signup">
                  Sign me up
                </button>
              </form>
              <p className="privacy-text text-center">
                By signing up via text, you agree to receive recurring automated marketing text messages at the cell
                number used when signing up. Consent is not a condition of any purchase. Msg & data rates may apply.
                View <a href="#">Terms & Privacy.</a>
              </p>
            </div>
          </div>
        </div>
      </Container>
    </Section>
  )
}

export default SignUpSection
